





















































import merge from "../utils/merge";
import defaultComponent from "../utils/defaultComponent";
import DataContainer from "../common/DataContainer";

export default defaultComponent.apply({
  props: ["requireIgnored", "value"],
  data() {
    return {
      originalData: null,
      type: "text",
      inputContainer: new DataContainer(),
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(v) {
        if (this.model.value === undefined) {
          this.model.value = v;
        }
      },
    },
  },
  methods: {
    initialize() {
      const typeMapping = this.$vuetifyx.input.typeMappings[this.options.type || this.type];
      if (typeMapping) {
        this.options.type = typeMapping.type;
        this.options = merge.clone(typeMapping, this.options);
      }

      if (this.options.type) {
        this.type = this.options.type;
      }

      this.options = merge.clone(
        {
          attrs: {
            rules: [],
          },
          content: {
            rules: {},
          },
        },
        this.options
      );
      this.options = merge.clone(
        {
          attrs: this.$vuetifyx.input.attrs,
        },
        this.options
      );
      if (this.requireIgnored) {
        this.options.attrs.required = false;
      }
      if (this.options.attrs.required) {
        if (!this.options.attrs.rules) {
          this.options.attrs.rules = [];
        }
        this.options.attrs.rules.push((v) => !!v || `The ${this.options.attrs.label} field is required.`);
        this.options.content.rules.required = {};
      }

      if (this.options.content.contextRules) {
        const newRules = this.options.content.contextRules(this.context(), this);
        for (const k in newRules) {
          this.options.content.rules[k] = newRules[k];
        }
      }
    },
  },
  extraOptions: {
    forwardStates: {
      xDisabled: ["disabled", false],
      xModel: ["model"],
      xLoading: ["loading", false],
    },
  },
});
